import React from "react";
import styled from "styled-components";
import Row from "components/Row";
import Flex from "../Box/Flex";
import MenuList from "./config";
import { useResponsive } from "../../utils/useResponse";
import Dropdown from "rsuite/Dropdown";
import "rsuite/styles/index.less";
import MenuMobile from "./MenuMobile";

const Imgs = {
  logoWeb: require("../../assets/images/logo-web.svg").default,
  kucoinLab: require("../../assets/images/kucoin_labs.png"),
  peckshield: require("../../assets/images/peckshield.png"),
};

const WrapperWeb = styled.div`
  width: 100%;
  height: 60px;
  background: white;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoWeb = styled.img`
  height: 36px;
  object-fit: cover;
  margin-right: 45px;
`;

const LogoAudit = styled.img`
  height: 31px;
  object-fit: cover;
  margin-left: 32px;
  cursor: pointer;
`;

const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid rgba(115, 126, 141, 0.16);
  z-index: 20;
  transform: translate3d(0, 0, 0);
  height: 60px;
  position: relative;
  .rs-dropdown-menu {
    position: absolute;
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 12px 23px rgba(0, 0, 0, 0.12);
    border: none;
  }
  .rs-dropdown-toggle,
  .rs-dropdown-toggle.rs-btn {
    border: none;
    background: transparent;
    color: #01142a;
    font-size: 16px;
    // margin: 0 24px;
    padding: 10px 24px;
    display: flex;
    :hover {
      &.rs-btn-disabled {
        color: white;
        opacity: 0.75;
      }
      color: #14d89d;
    }
  }
  .rs-btn:focus-visible {
    outline: none;
  }
`;

const MoreText = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-top: -10px;
  letter-spacing: 0.15rem;
  :hover {
    color: #14d89d;
  }
`;

export const ImgMenuIcon = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 12px;
`;

const DropDownContainer = styled.div`
  width: 375px;
  border-radius: 8px;
  padding: 8px 0;
  background: white;
`;

const DropDownItem = styled.div`
  padding: 12px 20px;
  cursor: pointer;
  .menu-hover {
    display: none;
  }
  :hover {
    .menu-hover {
      display: block;
    }
  }
`;

export const StatusTag = styled.div<{ color: string }>`
  height: 18px;
  padding: 0 8px;
  background: ${({ color }) => color};
  color: white;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  margin-left: 4px;
  border-radius: 10px;
`;

const Text = styled.div<{
  fontSize?: string;
  color?: string;
  lines?: number;
  ellipsis?: boolean;
  margin?: string;
}>`
  font-size: ${({ fontSize }) => fontSize ?? "16px"}};
  color: ${({ color }) => color ?? "#01142A"}};
  margin: ${({ margin }) => margin ?? "0"};
  ${({ ellipsis }) =>
    ellipsis &&
    `white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;`}

  ${({ lines }) =>
    `-webkit-line-clamp: ${lines ?? 0};
  line-clamp: ${lines ?? 0};
  display: -webkit-box;
  white-space: ${lines ? "normal" : "none"};
  -webkit-box-orient: vertical;`}
`;

const Link = styled.a`
  color: #01142a;
  font-size: 16px;
  margin: 0 24px;
  :hover {
    color: #14d89d;
  }
`;

const Navigation: React.FC = (props) => {
  return (
    <Row align="center">
      {MenuList.map((data, index) => {
        const isMore = index === MenuList.length - 1;
        const title = isMore ? <MoreText>{data.label}</MoreText> : data.label;

        if (!data.items) {
          return (
            <Link target={"_blank"} href={data.href} rel="noreferrer">
              {data.label}
            </Link>
          );
        }

        return (
          <>
            <Dropdown title={title} trigger="hover" noCaret key={data.label}>
              <DropDownContainer>
                {data.items?.map((item, itemIndex) => {
                  return (
                    <a
                      target={"_blank"}
                      href={item.href}
                      key={item.href}
                      rel="noreferrer"
                    >
                      <DropDownItem
                        style={{ marginTop: itemIndex > 0 ? "4px" : "0" }}
                      >
                        <Flex
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Flex
                            alignItems="flex-start"
                            key={item.label}
                            className="menu-content"
                          >
                            <ImgMenuIcon src={item.icon} />
                            <div>
                              <Flex alignItems="center">
                                <Text fontSize="14px" lines={1} ellipsis>
                                  {item.label}
                                </Text>
                                {item.status ? (
                                  <StatusTag color={item.status?.color}>
                                    {item.status?.text}
                                  </StatusTag>
                                ) : null}
                              </Flex>
                              <Text
                                fontSize="12px"
                                color="#666666"
                                lines={1}
                                ellipsis
                                margin="5px 0 0"
                              >
                                {item.intro}
                              </Text>
                            </div>
                          </Flex>
                        </Flex>
                      </DropDownItem>
                    </a>
                  );
                })}
              </DropDownContainer>
            </Dropdown>
          </>
        );
      })}
    </Row>
  );
};

const MenuWeb: React.FC = (props) => {
  return (
    <StyledNav>
      <WrapperWeb>
        <Row align="center">
          <LogoWeb src={Imgs.logoWeb} alt="logo" />
          <Navigation />
        </Row>
        <Row align="center">
          <LogoAudit
            src={Imgs.kucoinLab}
            alt="labs"
            onClick={() => {
              window.open(
                "https://twitter.com/MojitoSwap/status/1504035578706563074?s=20",
                "_blank"
              );
            }}
          />
          <LogoAudit
            src={Imgs.peckshield}
            alt="peck"
            onClick={() => {
              window.open(
                "https://docs.mojitoswap.finance/audit-report",
                "_blank"
              );
            }}
          />
        </Row>
      </WrapperWeb>
    </StyledNav>
  );
};

const Menu: React.FC = (props) => {
  const { isMobile } = useResponsive();
  if (isMobile) return <MenuMobile />;
  return <MenuWeb />;
};

export default Menu;
