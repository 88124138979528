import { XSwapWidget, Theme } from '@xyfinance/widget';
import Container from './components/Container';
import Menu from 'components/Menu';
import { useResponsive } from 'utils/useResponse';

const widgetConfig = {
  defaultChain: '1',
  disabledChains: ['10507', '108', '592', '1285', '8217', '1111'],
  slippage: '1.5',
  fromInput: '',
  referrer: '0x0De5fbD12B8058C52a1C131e64555603CB48E253',
  fromToken: {
    address: '0x55d398326f99059ff775485246999027b3197955',
    chainId: '56',
  },
  toToken: {
    address: '0x0039f574ee5cc39bdd162e9a88e3eb1f111baf48',
    chainId: '321',
  },
  featuredTokens: [
    // {
    //   address: "0x666666661f9B6D8c581602AAa2f76cbead06C401",
    //   chainId: "56",
    // },
    // {
    //   address: '0x55555555a687343c6ce28c8e1f6641dc71659fad',
    //   chainId: '137'
    // },
    // {
    //   address: '0x444444443b0fcb2733b93f23c910580fba52fffa',
    //   chainId: '250'
    // }
  ],
};

const theme: Theme = {
  mode: 'light',
  fontFamily: 'proxima-nova',
  borderRadius: {
    container: '12px',
    inner: '8px',
    button: '32px',
  },
  colors: {
    primary: '#14D89D',
    gradient: ['#14D89D', '#0AC4DD'],
  },
  components: {
    button: {
      variant: 'gradient',
    },
  },
};

function App() {
  const { isMobile } = useResponsive();
  return (
    <>
      <Menu />
      <Container>
        <div
          style={{
            width: isMobile ? `calc(100vw - 30px)` : '480px',
            margin: isMobile ? '20px auto' : '60px auto',
          }}
        >
          <XSwapWidget config={widgetConfig} theme={theme} />
        </div>
      </Container>
    </>
  );
}

export default App;
