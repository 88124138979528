export default {
  Swap: require("assets/images/dropDown/swap.png"),
  Liquidity: require("assets/images/dropDown/liquidity.png"),
  Rolling: require("assets/images/dropDown/rolling.png"),
  Bar: require("assets/images/dropDown/bar.png"),
  Pools: require("assets/images/dropDown/pool.png"),
  Sharker: require("assets/images/dropDown/shaker.png"),
  Referral: require("assets/images/dropDown/referral.png"),
  Launchpad: require("assets/images/dropDown/launchpad.png"),
  NftOverview: require("assets/images/dropDown/overview.png"),
  NftProfile: require("assets/images/dropDown/profile.png"),
  Collection: require("assets/images/dropDown/collections.png"),
  Games: require("assets/images/dropDown/games.png"),
  Box: require("assets/images/dropDown/box.png"),
  Analytics: require("assets/images/dropDown/analytics.png"),
  Docs: require("assets/images/dropDown/docs.png"),
  Discord: require("assets/images/dropDown/discord.png"),
  Medium: require("assets/images/dropDown/medium.png"),
  Twitter: require("assets/images/dropDown/twitter.png"),
  Github: require("assets/images/dropDown/github.png"),
  ProductG: require("assets/images/dropDown/product-g.svg"),
  Product: require("assets/images/dropDown/product.svg"),
  Bridge: require("assets/images/dropDown/bridge.png"),
  Telegram: require("assets/images/dropDown/telegram.png"),
  Multichain: require("assets/images/dropDown/multichain.png"),
  MjtLogo: require("assets/images/dropDown/mjt.png"),
  Banxa: require("assets/images/dropDown/banxa.png"),
  NFTV1: require("assets/images/dropDown/nftV1.png"),
  NFTV2: require("assets/images/dropDown/nftV2.png"),
};
