/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { DOCS, TWITTER, TELEGRAM, GITHUB, MEDIUM, DISCORD } from "./links";
import Icons from "./Icon";
import { LinkStatus, MenuEntry } from "./types";

const status = {
  NEW: <LinkStatus>{
    text: "NEW",
    color: "#FF5B65",
  },
  HOT: <LinkStatus>{
    text: "Hot",
    color: "#FF5B65",
  },
  ADDON: <LinkStatus>{
    text: "ADD-ON",
    color: "#FC9C47",
  },
  MIGRATING: <LinkStatus>{
    text: "MIGRATING",
    color: "#FC9C47",
  },
};

const PROD_MENU: MenuEntry[] = [
  {
    label: "Swap",
    href: "https://app.mojitoswap.finance",
    ga: "page_sidebar_trade",
  },
  {
    label: "Cross Chain",
    items: [
      {
        label: "KCC Bridge",
        href: "https://www.kcc.io/bridge/transfer",
        icon: Icons.Bridge,
        intro: "Link to Other Public Chains",
        status: status.ADDON,
      },
      {
        href: "https://app.multichain.org/#/router",
        label: "Multichain",
        icon: Icons.Multichain,
        intro: "Support for asset exchange across multiple chains",
        status: status.ADDON,
      },
    ],
  },
  {
    label: "...",
    items: [
      {
        label: "Analytics",
        icon: Icons.Analytics,
        href: "https://app.mojitoswap.finance/info",
        intro: "Charts and statistics",
      },
      {
        label: "Docs",
        href: DOCS,
        icon: Icons.Docs,
        intro: "Info about MojitoSwap",
      },
      {
        label: "Medium",
        href: MEDIUM,
        ga: "page_sidebar_More_medium",
        icon: Icons.Medium,
        intro: "Join Medium now",
      },
      {
        label: "Telegram",
        href: TELEGRAM,
        icon: Icons.Telegram,
        intro: "Join Telegram now",
      },
      {
        label: "Twitter",
        href: TWITTER,
        icon: Icons.Twitter,
        intro: "Join Twitter now",
      },
      {
        label: "Discord",
        href: DISCORD,
        icon: Icons.Discord,
        intro: "Join Discord now",
      },
      {
        label: "Github",
        href: GITHUB,
        icon: Icons.Github,
        intro: "Join Github now",
      },
    ],
  },
];

const config = PROD_MENU;

export default config;
